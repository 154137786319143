import React from 'react'
import { NavLink } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'aos/dist/aos.css'
import 'swiper/css'
import Header from '../component/Header'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Footer from '../component/Footer'
import { useEffect } from 'react'


function Home() {
  const testimonials = [
    {
      title: "It is just incredible!",
      content: "It’s been 6 months since I started using Billery to manage my clients transactions, and the result is very satisfying, I'm glad I can connect to various payment gateways!",
      name: "Tassy Omah",
      role: "Product Designer",
    },
    {
      title: "Amazing Software!",
      content: "I'be been using Billery For a while now and it's really an amazing software, very easy to use, I'll recommend!",
      name: "Ndubuisi Onyemenam",
      role: "Software Engineer",
    },
    {
      title: "Time-Saver!",
      content: "I used to spend hours every month managing invoices and tracking payments. This app has completely streamlined the process! With just a few clicks, I can send invoices and get paid, It’s an absolute time-saver.",
      name: "Austin Amadi",
      role: "Software Engineer",
    },
    {
      title: "No doubt, Billery is the best!",
      content: "“The best”! That’s what I want to say to this platform, didn’t know that there’s a platform to help you to manage.",
      name: "Winner Emeto",
      role: "Tech Specialist",
    },
  ];

  useEffect(() => {
    document.title = "Free Invoicing App | Send and receive bills"; // Set the document title
  }, []);

  return (
    <>
    <Header />
<main className="nk-pages">
  {/* Banner Section Start */}
  <section className="nk-banner nk-banner-project-manage overflow-hidden bg-gray">
  <div className="nk-banner-wrap pb-lg-8">
    <div className="container">
      <div className="row align-items-lg-center">
        <div className="col-lg-6 pb-5 pb-md-7 pb-lg-0">
          <div className="text-center text-lg-start">
            <h1
              className="display-6 mb-4 pe-lg-5"
              data-aos="fade-up"
              data-aos-delay={0}
            >
              {" "}
              The Billing App for Freelancers{" "}
              <span className="text-primary"> And SMEs</span>
            </h1>
            <p className="text-dark m-0" data-aos="fade-up" data-aos-delay={50}>
              {" "}
              Your one-stop invoicing and accounting platform. Manage all your
              billing, payments and customers with billery.{" "}
            </p>
            <ul className="nk-btn-group justify-content-center justify-content-lg-start pt-5">
              <li data-aos="fade-up" data-aos-delay={100}>
                <a
                  href="https://app.billery.io/auth/register"
                  className="btn btn-outline-primary"
                >
                  Get Started for free
                </a>
              </li>
            </ul>
            <div className="pt-5">
              <ul className="d-flex justify-content-center justify-content-lg-start flex-wrap gap-5 gap-lg-7 align-items-center">
                <li data-aos="fade-up" data-aos-delay={125}>
                  <span className="d-block h3 m-0">5k+</span>
                  <p className="fs-16 text-dark">Freelancers</p>
                </li>
                <li data-aos="fade-up" data-aos-delay={150}>
                  <span className="d-block h3 m-0">7k+</span>
                  <p className="fs-16 text-dark">Bills Processed</p>
                </li>
                <li data-aos="fade-up" data-aos-delay={175}>
                  <span className="d-block h3 m-0">25</span>
                  <p className="fs-16 text-dark">Countries</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="nk-frame mb-5 text-center text-lg-end"
            data-aos="fade-up"
            data-aos-delay={0}
          >
            <img
              src="images/thumb/herofreelancer-1.svg"
              alt="project-manage"
              className="position-relative overflow-hidden rounded-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  {/* Banner Section End  */}
  {/* Feature Section Start */}
  <section className="nk-section overflow-hidden">
  <div className="nk-mask blur-1 left top" />
  <div className="nk-mask blur-1 right bottom" />
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8 col-xxl-6">
        <div className="nk-section-head text-center">
          <span className="nk-section-subtitle">Features</span>
          <h2 className="nk-section-title">Discover Powerful Features</h2>
          <p className="nk-section-text">
            Easy. Simple. Affordable. Enjoy seamless billings for your business
            today.
          </p>
        </div>
      </div>
    </div>
    <div className="row gy-5">
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={0}>
        <div className="card border-0 bg-primary-100">
          <div className="card-body">
            <div className="nk-feature-block-content">
              <div className="media media-lg media-middle media-circle text-bg-primary mb-3 mb-lg-5">
                <em className="icon ni ni-files-fill" />
              </div>
              <h4 className="mb-2">Send and Request bills using AI</h4>
              <p className="fs-16 m-0 text-gray-800">
                Send and request invoices and estimates with your customers from
                anywhere in the world and get paid faster.
              </p>
            </div>
          </div>
          {/* <div class="text-start" data-aos="fade-right" data-aos-offset="90" data-aos-delay="50">
                              <img src="images/thumb/AI-web.png" alt="feature" class="pe-3 pe-md-7">
                          </div> */}
        </div>
      </div>
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay={50}>
        <div className="card border-0 bg-primary-100">
          <div className="card-body">
            <div className="nk-feature-block-content">
              <div className="media media-lg media-middle media-circle text-bg-cyan-200 text-white mb-3 mb-lg-5">
                <em className="icon ni ni-wallet-fill" />
              </div>
              <h4 className="mb-2">Take payment instantly</h4>
              <p className="fs-16 m-0 text-gray-800">
                Connect to Stripe, PayPal, Paystack or Crypto and take payment
                instantly without any hasstle while we keep track.
              </p>
            </div>
          </div>
          {/* <div class="text-end" data-aos="fade-left" data-aos-offset="90" data-aos-delay="100">
                              <img src="images/thumb/paystack.png" alt="feature" class=" ps-3 ps-md-7">
                          </div> */}
        </div>
      </div>
      <div className="col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay={0}>
        <div className="card border-0 bg-primary-100">
          <div className="card-body">
            <div className="media media-lg media-middle media-circle text-bg-warning text-white mb-3 mb-lg-5">
              <em className="icon ni ni-link" />
            </div>
            <h4 className="mb-2">Payment Links</h4>
            <p className="fs-16 text-gray-800 ">
              {" "}
              Create a payment link for instant payment and start taking fee
              payments, donations, etc.
            </p>
          </div>
        </div>
      </div>
      <div
        className="col-md-6 col-xl-4"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="card border-0 bg-primary-100">
          <div className="card-body">
            <div className="media media-lg media-middle media-circle text-bg-danger mb-3 mb-lg-5">
              <em className="icon ni ni-coin" />
            </div>
            <h4 className="mb-2">Multiple currencies </h4>
            <p className="fs-16 text-gray-800 ">
              Charge customers in their native currency and get paid in the
              currency of your choice
            </p>
          </div>
        </div>
      </div>
      <div
        className="col-md-6 col-xl-4"
        data-aos="fade-up"
        data-aos-delay={200}
      >
        <div className="card border-0 bg-primary-100">
          <div className="card-body">
            <div className="media media-lg media-middle media-circle text-bg-primary mb-3 mb-lg-5">
              <em className="icon ni ni-users" />
            </div>
            <h4 className="mb-2">Manage customers</h4>
            <p className="fs-16 text-gray-800 ">
              Quickly capture your customers details for reoccurring bills and
              payment reminders.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  {/* Feature Section End */}
  {/* Works Section End  */}
  <section className="nk-section nk-how-it-work-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-xl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle">Billery Process</span>
            <div>
              <h2 className="nk-section-title">See how Billery works</h2>
              <p className="nk-section-text">
                {" "}
                Experience our unique approach and discover how we help you with
                your billings and streamline your business finances.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul className="nk-timeline-group gap g-5 has-divider">
        <li
          className="nk-timeline-group-item"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <div className="nk-timeline-group-content text-center">
            <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-3 mb-lg-6 fw-semibold">
              <span className="h4 text-primary">1</span>
            </div>
            <div>
              <span className="d-inline-block fs-14 fw-semibold text-gray-800 text-uppercase mb-3">
                step 1
              </span>
              <div>
                <h3 className="mb-3">Create Account</h3>
                <p className="fs-18 text-gray-800 line-clamp-3">
                  Sign up in just a few easy step with Billery for free.
                </p>
              </div>
            </div>
          </div>
        </li>
        <li
          className="nk-timeline-group-item"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="nk-timeline-group-content text-center">
            <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-3 mb-lg-6 fw-semibold">
              <span className="h4 text-primary">2</span>
            </div>
            <div>
              <span className="d-inline-block fs-14 fw-semibold text-gray-800 text-uppercase mb-3">
                step 2
              </span>
              <div>
                <h3 className="mb-3">Add Business</h3>
                <p className="fs-18 text-gray-800 line-clamp-3">
                  Set up your business and preferred payment gateway.
                </p>
              </div>
            </div>
          </div>
        </li>
        <li
          className="nk-timeline-group-item"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="nk-timeline-group-content text-center">
            <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-3 mb-lg-6 fw-semibold">
              <span className="h4 text-primary">3</span>
            </div>
            <div>
              <span className="d-inline-block fs-14 fw-semibold text-gray-800 text-uppercase mb-3">
                step 3
              </span>
              <div>
                <h3 className="mb-3">Send Bills</h3>
                <p className="fs-18 text-gray-800 line-clamp-3">
                  Send and receive bills, payment links, and in-person payment.
                </p>
              </div>
            </div>
          </div>
        </li>
        <li
          className="nk-timeline-group-item"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <div className="nk-timeline-group-content text-center">
            <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-3 mb-lg-6 fw-semibold">
              <span className="h4 text-primary">4</span>
            </div>
            <div>
              <span className="d-inline-block fs-14 fw-semibold text-gray-800 text-uppercase mb-3">
                step 4
              </span>
              <div>
                <h3 className="mb-3">Receive Payments</h3>
                <p className="fs-18 text-gray-800 line-clamp-3">
                  {" "}
                  Receive and make payments, send receipt instantly.
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      {/* .nk-timeline-group */}
    </div>
  </section>
  {/* Works Section End  */}
  {/* Analytics Section Start  */}
  <section className="nk-section py-7 py-lg-120">
    <div className="container">
      <div className="row pb-5 pb-md-7 justify-content-center justify-content-xl-between align-items-xl-end">
        <div className="col-md-8 col-xl-5">
          <div className="nk-section-head text-center text-xl-start pb-5 pb-xl-0">
            <span className="nk-section-subtitle">Who needs billery</span>
            <h2 className="nk-section-title">Industries</h2>
            <p className="nk-section-text">
              Crafted for businesses of all shapes and sizes
            </p>
          </div>
        </div>
        <div className="col-xl-6">
          <ul className="nk-btn-group justify-content-center justify-content-xl-end">
            <li>
              <NavLink to="https://app.billery.io/auth/register" className="btn btn-primary">
                Get started
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="row gy-5 gy-lg-0">
        <div
          className="pb-6 col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="images/blog/retail.png"
                  alt="cover-img"
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={50}
                />
              </div>
              <h4>Online Retail Store</h4>
              <p>
                We provide automatic invoice payment services and payment links
                to get your business up and running
              </p>
            </div>
          </div>
        </div>
        <div
          className="pb-6 col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="images/blog/photography.png"
                  alt=""
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={100}
                />
              </div>
              <h4>Professional Services</h4>
              <p>
                Keep selling assistance and breakdown cover, turn your passion
                into money in your bank account in just a few steps
              </p>
            </div>
          </div>
        </div>
        <div
          className="pb-6 col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="/images/blog/freelancer.png"
                  alt=""
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={150}
                />
              </div>
              <h4>Freelancers</h4>
              <p>
                Designers, software engineers and other freelancers can get paid
                while working at the comfort of their home
              </p>
            </div>
          </div>
        </div>
        <div
          className="pb-6 col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={0}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="/images/blog/beauty-wellness.png"
                  alt="cover-img"
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={50}
                />
              </div>
              <h4>Beauty and wellness </h4>
              <p>
                Lashes, curls, or in-demand esthetician, get paid anywhere in
                few minutes
              </p>
            </div>
          </div>
        </div>
        <div
          className="pb-6 col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="/images/blog/travel.png"
                  alt=""
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={100}
                />
              </div>
              <h4>Travel and tourism</h4>
              <p>
                Are you hosting a corporate, leisure or influencer getaway, take
                payments in no time and enjoy a seamless travel.{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          className="pb-6 ....col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="nk-feature-block">
            <div className="nk-feature-block-content">
              <div className="nk-feature-img position-relative overflow-hidden rounded-4 mb-3 mb-md-5 border border-light bg-gray">
                <img
                  src="/images/blog/event-management.png"
                  alt=""
                  className="img-fluid"
                  data-aos="fade-in"
                  data-aos-delay={150}
                />
              </div>
              <h4>Events</h4>
              <p>
                Food or music, handle at the door ticket sales, enhance revenue
                with gorgeous merchandise, and improve your cashflow
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Analytics Section End   */}
  {/* Testimonials Section Start  */}
  <section className="nk-section overflow-hidden">
    <div className="nk-mask shape-11" data-aos="fade-up" data-aos-delay={0} />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-xxl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle">what they say</span>
            <h2 className="nk-section-title">Our User Kind Words</h2>
            <p className="nk-section-text">
              Hear the kind words from our satisfied users who have experienced
              our exceptional service firsthand. Join them today!
            </p>
          </div>
        </div>
      </div>
      <div className="swiper-container">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            992: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
          }}
        >
            <div className="swiper-wrapper">
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index} className="swiper-slide h-auto">
                <div className="nk-testimonial-wrap p-0 nk-testimonial-wrap-s1 h-100">
                  <div className="nk-testimonial-card p-5 nk-testimonial-card-s1">
                    <div className="nk-testimonial-content d-flex flex-column justify-content-between">
                      <div>
                        <h5 className="mb-3 text-dark">{testimonial.title}</h5>
                        <p>{testimonial.content}</p>
                      </div>
                      <div className="media-group align-items-center pt-4">
                        {/* <div className="media media-md media-circle">
                          <img src={testimonial.image} alt="avatar" />
                        </div> */}
                        <div className="media-text">
                          <h6 className="mb-0 text-dark">{testimonial.name}</h6>
                          <span className="smaller text">{testimonial.role}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="swiper-button-group swiper-button-group-s1 justify-content-center pt-4 pt-md-6">
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </div>
        </Swiper>
      </div>
  </div>
  </section>
  {/* Testimonials Section End */}
  {/* Integration Section Start  */}
  <section className="nk-section pt-7  pt-lg-120 pb-5 pb-lg-120">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8 col-xxl-6">
        <div className="nk-section-head pb-4 text-center">
          <span className="nk-section-subtitle">Integrations</span>
          <h2 className="nk-section-title">Payment Integrations</h2>
          <p className="nk-section-text">
            {" "}
            Unlock limitless possibilities with fast payment integrations,
            empowering seamless payments for your business.{" "}
          </p>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="row gy-2 gy-lg-0 align-items-center justify-content-center">
          <div className="col-4 col-sm-3 col-lg-2">
            <div className="nk-brand" data-aos="fade-up" data-aos-delay={50}>
              <a href="https://paypal.com" target="_blank">
                <img src="images/thumb/paypal.svg" alt="brand" />
              </a>
            </div>
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <div className="nk-brand" data-aos="fade-up" data-aos-delay={100}>
              <a href="https://stripe.com" target="_blank">
                <img src="images/thumb/stripe.svg" alt="brand" />
              </a>
            </div>
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <div className="nk-brand" data-aos="fade-up" data-aos-delay={150}>
              <a href="https://tether.to" target="_blank">
                <img src="images/thumb/tether.svg" alt="brand" />
              </a>
            </div>
          </div>
          <div className="col-4 col-sm-3 col-lg-2">
            <div className="nk-brand" data-aos="fade-up" data-aos-delay={200}>
              <a href="https://paystack.com" target="_blank">
                <img src="images/thumb/Paystack.svg" alt="brand" />
              </a>
            </div>
          </div>
        </div>
        <ul className="nk-btn-group justify-content-center pt-7">
          <li data-aos="fade-up" data-aos-delay={100}>
            <a href="https://app.billery.io" className="btn btn-primary">
              Get started
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
  {/* Integration Section End  */}
  {/* Pricing Section Start  */}
  <section className="nk-section">
  <div className="container">
    <div
      className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
      data-aos="fade-up"
      data-aos-delay={100}
    >
      <div className="row g-0 align-items-center overflow-hidden">
        <div className="col-lg-7 order-lg-0 order-2">
          <div className="nk-block-head-content  mb-0">
            <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
              Start billing your customers and boost your Business finances
            </h2>
            <ul className="nk-btn-group flex-wrap ps-1">
              <li>
                <a
                  href="https://app.billery.io/auth/register"
                  className="btn btn-white"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-5 order-lg-0 order-1">
          <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
            <img
              data-aos="fade-left"
              data-aos-delay={200}
              src="images/thumb/c.png"
              alt="tracking-thumb"
              className="me-n1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</main>
<Footer />
</>
  )
}

export default Home