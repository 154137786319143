import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { useEffect } from 'react';

function TermsAndCond() {

  useEffect(() => {
    document.title = "Billery - Terms of Service"; // Set the document title
  }, []);

  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Terms Section Start */}
  <section className="nk-section pt-120 pt-lg-180">
    <div className="nk-mask blur-1 left top" />
    <div className="nk-mask blur-1 right bottom" />
    <div className="container">
      <div className="nk-block-head md">
        <div className="nk-section-head">
          <nav>
            <ol className="breadcrumb mb-3 mb-md-4">
              <li className="breadcrumb-item">
                <a href="help-center.html">Support</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Terms &amp; Conditions
              </li>
            </ol>
          </nav>
          <h2>Terms &amp; Conditions</h2>
        </div>
        <hr className="mt-5 mb-0" />
      </div>
      <div className="row g-gs pt-3 ">
        <div className="col-lg-12">
          <div className="nk-entry-wrap pt-3 ps-lg-5">
            <div className="nk-entry">
              <div className="pb-5">
                <h5>User Accounts</h5>
                <p className="fs-16">
                  You must create an account to use the System. You are
                  responsible for maintaining the security and confidentiality
                  of your account credentials.
                </p>
                <p className="fs-16">
                  You are solely responsible for all activities that occur under
                  your account, including any actions taken by authorized or
                  unauthorized users.
                </p>
              </div>
              <div className="pb-5">
                <h5>Use of the System</h5>
                <p className="fs-16">
                  You may use the System for your internal business purposes,
                  subject to these Terms.
                </p>
                <p className="fs-16">
                  You shall not use the System for any illegal, unethical, or
                  harmful activities.
                </p>
              </div>
              <div className="pb-5">
                <h5>Data and Privacy</h5>
                <p className="fs-16">
                  You agree that we may collect and use information about your
                  use of the System as described in our Privacy Policy.
                </p>
                <p className="fs-16">
                  You are responsible for the accuracy and legality of the data
                  you input into the System.{" "}
                </p>
              </div>
              <div className="pb-5">
                <h5>Intellectual Property</h5>
                <p className="fs-16">
                  {" "}
                  The System, including all content, software, and materials, is
                  protected by intellectual property laws. You may not copy,
                  modify, distribute, or reverse engineer the System.
                </p>
              </div>
              <div className="pb-5">
                <h5>Payment</h5>
                <p className="fs-16">
                  You agree to pay all fees associated with your use of the
                  System as specified in our pricing plan.
                </p>
                <p className="fs-16">
                  All fees are non-refundable unless expressly stated otherwise
                  in writing.
                </p>
              </div>
              <div className="pb-5">
                <h5>Support and Maintenance</h5>
                <p className="fs-16">
                  {" "}
                  We will provide reasonable technical support for the System
                  during the term of your subscription.
                </p>
                <p className="fs-16">
                  We may perform scheduled maintenance, and you may experience
                  interruptions in System availability.
                </p>
              </div>
              <div className="pb-5">
                <h5>Termination</h5>
                <p className="fs-16">
                  {" "}
                  We may terminate your access to the System at any time for
                  violations of these Terms or for any other reason, with or
                  without notice.
                </p>
                <p className="fs-16">
                  Upon termination, you must cease all use of the System, and we
                  may delete your account and data.
                </p>
              </div>
              <div className="pb-5">
                <h5>Limitation of Liability</h5>
                <p className="fs-16">
                  To the fullest extent permitted by law, we shall not be liable
                  for any indirect, incidental, special, consequential, or
                  punitive damages.
                </p>
              </div>
              <div className="pb-5">
                <h5>Indemnification</h5>
                <p className="fs-16">
                  {" "}
                  You agree to indemnify and hold us harmless from any claims,
                  damages, or liabilities arising out of your use of the System
                  or breach of these Terms.
                </p>
              </div>
              <div className="pb-5">
                <h5>Changes to Terms</h5>
                <p className="fs-16">
                  {" "}
                  We may update these Terms from time to time. You will be
                  notified of any material changes, and your continued use of
                  the System constitutes acceptance of the updated Terms.
                </p>
              </div>
              <div className="pb-5">
                <h5>Governing Law</h5>
                <p className="fs-16">
                  {" "}
                  These Terms shall be governed by and construed in accordance
                  with the laws of the United Kingdom.
                </p>
              </div>
              <div className="pb-5">
                <h5>Contact Information</h5>
                <p className="fs-16">
                  {" "}
                  If you have any questions or concerns about these Terms or the
                  System, please contact us at support@myinvoicer.app.
                </p>
              </div>
              <div className="pb-5">
                <h5>Agreement</h5>
                <p className="fs-16">
                  By using MyInvoicer, you acknowledge that you have read,
                  understood, and agree to these Terms and our Privacy Policy.
                </p>
              </div>
            </div>
            {/* .nk-entry */}
          </div>
          {/* .nk-entry-wrap */}
        </div>
      </div>
    </div>
  </section>
  {/* Terms Section End */}
  &lt;{/* CTA Section Start  */}
  <section className="nk-section">
          <div className="container">
            <div
              className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="row g-0 align-items-center overflow-hidden">
                <div className="col-lg-7 order-lg-0 order-2">
                  <div className="nk-block-head-content  mb-0">
                    <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                      Start billing your customers and boost your Business finances
                    </h2>
                    <ul className="nk-btn-group flex-wrap ps-1">
                      <li>
                        <a
                          href="https://app.billery.io/auth/register"
                          className="btn btn-white"
                        >
                          Get Started
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                    <img
                      data-aos="fade-left"
                      data-aos-delay={200}
                      src="images/thumb/c.png"
                      alt="tracking-thumb"
                      className="me-n1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default TermsAndCond