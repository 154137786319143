import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
import { useEffect } from 'react'

function HelpCenter() {

  useEffect(() => {
    document.title = "Billery - Support"; // Set the document title
  }, []);

  return (
    <>
    <Header />
        <main className="nk-pages">
  {/* Support Section Start*/}
  <section className="nk-section pt-120 pt-lg-160">
    <div className="nk-mask blur-1 left center" />
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 col-xxl-6">
          <div className="nk-section-head text-center">
            <span
              className="nk-section-subtitle"
              data-aos="fade-up"
              data-aos-delay={0}
            >
              Our support
            </span>
            <h2
              className="nk-section-title"
              data-aos="fade-up"
              data-aos-delay={50}
            >
              How can we help?
            </h2>
            <p
              className="nk-block-text"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              {" "}
              We can help you with a wide range of services and solutions to
              your specific needs. Let us know how we can assist you!{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="row gy-5">
        {/* <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                      <div class="card">
                          <div class="card-body">
                              <div class="media media-lg media-middle media-circle text-bg-primary-soft mb-5">
                                  <em class="icon ni ni-wallet-fill"></em>
                              </div>
                              <h4 class="title text-capitalize mb-3">Billing &amp; Payments</h4>
                              <p class="line-clamp-3"> Our billing and payment system is designed to make your financial transactions smooth and hassle-free. Manage your invoices, track payments, and enjoy a seamless billing experience with us. </p>
                              <div class="pt-2">
                                  <NavLink to="billing" class="btn-link text-primary">
                                      <span>See Pricing Questions</span>
                                      <em class="icon ni ni-arrow-right"></em>
                                  </NavLink>
                              </div>
                          </div>
                      </div>
                  </div> */}
        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={50}>
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-primary-soft mb-5">
                <em className="icon ni ni-info-fill" />
              </div>
              <h4 className="title text-capitalize mb-3">Terms of service</h4>
              <p className="line-clamp-5">
                These terms of service govern your use of the MyInvoicer. By
                accessing or using the System, you agree to comply with and be
                bound by these Terms. If you do not agree to these Terms, please
                do not use the System.{" "}
              </p>
              <div className="pt-2">
                <NavLink
                  to="/terms-and-condition"
                  className="btn-link text-primary"
                >
                  <span>See Our Terms</span>
                  <em className="icon ni ni-arrow-right" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                      <div class="card">
                          <div class="card-body">
                              <div class="media media-lg media-middle media-circle text-bg-danger-soft mb-5">
                                  <em class="icon ni ni-puzzle-fill"></em>
                              </div>
                              <h4 class="title text-capitalize mb-3">Features and Integrations</h4>
                              <p class="line-clamp-3"> Unlock a wide range of powerful features and integrations that enhance your workflow and streamline your business processes. From project management and communication tools to data analysis and reporting capabilities, our platform offers comprehensive solutions to meet your specific needs. Integrate with popular third-party apps and services to further extend the functionality of our platform and optimize your business operations. </p>
                              <div class="pt-2">
                                  <NavLink to="#" class="btn-link text-primary">
                                      <span>Browse Articles</span>
                                      <em class="icon ni ni-arrow-right"></em>
                                  </NavLink>
                              </div>
                          </div>
                      </div>
                  </div> */}
        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={50}>
          <div className="card">
            <div className="card-body">
              <div className="media media-lg media-middle media-circle text-bg-success-soft mb-5">
                <em className="icon ni ni-alert-fill" />
              </div>
              <h4 className="title text-capitalize mb-3">Privacy policy</h4>
              <p className="fs-16 line-clamp-5">
                This Privacy Policy explains how MyInvoicer collects, uses,
                discloses, and protects your personal information when you use
                the app. By using the System, you consent to the practices
                described in this Privacy Policy.{" "}
              </p>
              <div className="pt-2">
                <NavLink to="/privacy" className="btn-link text-primary">
                  <span>See Our Policies</span>
                  <em className="icon ni ni-arrow-right" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Support Section End */}
  {/* FAQ Section Start */}
  <section className="nk-section">
    <div className="nk-mask blur-1 right top" />
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-lg-8 col-xxl-6">
          <div className="nk-section-head text-center">
            <span className="nk-section-subtitle">FAQ</span>
            <h2 className="mbnk-section-title ">Frequently Asked Questions</h2>
            <p className="nk-section-text">
              {" "}
              Find answers to commonly asked questions about our product or
              service in our comprehensive FAQ section.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="row gy-5 gy-lg-0">
        <div className="col-lg-12">
          <div className="nk-faq">
            <div
              className="accordion accordion-s2 bg-white rounded-2 border"
              id="businessFaq"
            >
              <div
                className="accordion-item"
                data-aos="fade-up"
                data-aos-delay={0}
              >
                <div className="accordion-header">
                  <h6>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqCollapseOne"
                    >
                      <span className="text-capitalize">
                        What is Billery and what do they do?
                      </span>
                      <em className="icon ni ni-plus on" />
                      <em className="icon ni ni-minus off" />
                    </button>
                  </h6>
                </div>
                <div
                  id="faqCollapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#businessFaq"
                >
                  <div className="accordion-body">
                    <p className="fs-16">
                      Billery App is an AI Invoiceing tool that helps
                      businesses, freelancers, and NGO's to send, receive,
                      track, and manage their invoices and payments efficiently.
                      It streamlines the invoicing process, reduces manual work,
                      and improves cash flow management.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item"
                data-aos="fade-up"
                data-aos-delay={25}
              >
                <div className="accordion-header">
                  <h6>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqCollapseTwo"
                    >
                      <span className="text-capitalize">
                        How does Billery work?
                      </span>
                      <em className="icon ni ni-plus on" />
                      <em className="icon ni ni-minus off" />
                    </button>
                  </h6>
                </div>
                <div
                  id="faqCollapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#businessFaq"
                >
                  <div className="accordion-body">
                    <p className="fs-16">
                      {" "}
                      Billery App allows users to create bills such as
                      invoices, estimates, receipts and purchase orders and send
                      them to clients electronically. It automates tasks such as
                      reminders, and reconciliation, enhancing accuracy and
                      reducing administrative burden.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item"
                data-aos="fade-up"
                data-aos-delay={50}
              >
                <div className="accordion-header">
                  <h6>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqCollapseThree"
                    >
                      <span className="text-capitalize">
                        Can I customize my bills before sending to cutomers?
                      </span>
                      <em className="icon ni ni-plus on" />
                      <em className="icon ni ni-minus off" />
                    </button>
                  </h6>
                </div>
                <div
                  id="faqCollapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#businessFaq"
                >
                  <div className="accordion-body">
                    <p className="fs-16">
                      {" "}
                      Yes, Billery App offer customization options, allowing you
                      to add your logo, colors, and company information to the
                      invoices to match your brand identity.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item"
                data-aos="fade-up"
                data-aos-delay={75}
              >
                <div className="accordion-header">
                  <h6>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqCollapseFour"
                    >
                      <span className="text-capitalize">
                        Is my finacial data secure with the app?
                      </span>
                      <em className="icon ni ni-plus on" />
                      <em className="icon ni ni-minus off" />
                    </button>
                  </h6>
                </div>
                <div
                  id="faqCollapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#businessFaq"
                >
                  <div className="accordion-body">
                    <p className="fs-16">
                      {" "}
                      We use encryption and secure protocols to protect your
                      financial data. And we ensure robust security measures to
                      ensure data privacy.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="accordion-header">
                  <h6>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqCollapseFive"
                    >
                      <span className="text-capitalize">
                        Does the app support different payment methods?
                      </span>
                      <em className="icon ni ni-plus on" />
                      <em className="icon ni ni-minus off" />
                    </button>
                  </h6>
                </div>
                <div
                  id="faqCollapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#businessFaq"
                >
                  <div className="accordion-body">
                    <p className="fs-16">
                      {" "}
                      Yes, it allow you to include multiple payment methods on
                      your invoices, such as credit cards, bank transfers, and
                      digital wallets. We also help you integrate with various payment gateways like Stripe, PayPal and PayStack making it convenient for customers to
                      pay.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* FAQ Section End */}
  {/* CTA Section Start */}
  <section className="nk-section">
    <div className="container">
      <div
        className="nk-cta-wrap bg-primary-gradient rounded-3 is-theme p-5 p-lg-7"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div className="row g-gs align-items-center">
          <div className="col-lg-8">
            <div className="media-group flex-column flex-lg-row align-items-center">
              <div className="media media-lg media-circle media-middle text-bg-white text-primary mb-2 mb-lg-0 me-lg-2">
                <em className="icon ni ni-chat-fill" />
              </div>
              <div className="text-center text-lg-start ms-lg-3">
                <h3 className="text-capitalize m-0">
                  Chat with our support team!
                </h3>
                <p className="fs-16">
                  Get in touch with our support team if you still can’t find
                  your answer.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <NavLink to={"/contact"} className="btn btn-white">
              Contact support
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* CTA Section End */}
  {/* CTA Section Start  */}
  <section className="nk-section">
    <div className="container">
        <div
              className="nk-cta-wrap nk-cta-card bg-primary-gradient rounded-3 is-theme position-relative overflow-hidden"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="row g-0 align-items-center overflow-hidden">
                <div className="col-lg-7 order-lg-0 order-2">
                  <div className="nk-block-head-content  mb-0">
                    <h2 className="newsletter-heading text-capitalize h1 m-0 mb-4 mb-lg-7">
                      Start billing your customers and boost your Business finances
                    </h2>
                    <ul className="nk-btn-group flex-wrap ps-1">
                      <li>
                        <a
                          href="https://app.billery.io/auth/register"
                          className="btn btn-white"
                        >
                          Get Started
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="nk-cta-img-wrap text-end  ps-5 pt-7 pt-lg-0 ps-sm-6 ps-lg-0">
                    <img
                      data-aos="fade-left"
                      data-aos-delay={200}
                      src="images/thumb/c.png"
                      alt="tracking-thumb"
                      className="me-n1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  {/* CTA Section End  */}
</main>
<Footer />

    </>
  )
}

export default HelpCenter